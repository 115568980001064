/* eslint-disable no-unused-vars */
import Request from '@/services/axios'
import Vue from "vue";
let request = new Request;

const fetchListStatement = async({ commit }, preData) => {
    //console.log(preData.reportUrl)
    let data = preData.data
    let user_id = preData.user_id
    let reportUrl = preData.reportUrl
    Vue.$cookies.set("currency-type", 1, 0);
    Vue.$cookies.set("app_id", preData.appId, 0);
    let response;
    if(reportUrl == 'company'){
        response = await request.post({
            url: 'statementChannel/' + reportUrl,
            data,
        });
    }else{
        response = await request.post({
            url: 'statementChannel/' + reportUrl + '/' + user_id,
            data,
        });
    }
    if (response.data.code) {
        commit('LIST_STATEMENT', response.data.data);
        commit('COUNT_STATEMENT', response.data.data.objectCount);
    }

};
const fetchCountExceptSenior = async({ commit }, preData) => {
   
    let data = preData.data;
    let totalExceptCom = 0;
    data.sortByFormat.length < 1 ? data.sortBy = '_id._id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    Vue.$cookies.set("currency-type", 1, 0);
    Vue.$cookies.set("app_id", preData.appId, 0);
    const response = await request.post({
        url: 'statementChannel/exceptSenior',
        data,
    });
    if (response.data.code) {
        totalExceptCom = response.data.data.objectCount.amount_win;
    }
    return totalExceptCom;

};
const fetchCountExceptCompany = async({ commit }, preData) => {
   
    let data = preData.data;
    let totalExceptCom = 0;
    data.sortByFormat.length < 1 ? data.sortBy = '_id._id' : data.sortBy = data.sortByFormat[0]
    data.descendingFormat.length > 0 ? data.descending = data.descendingFormat[0] : data.descending = true
    Vue.$cookies.set("currency-type", 1, 0);
    Vue.$cookies.set("app_id", preData.appId, 0);
    const response = await request.post({
        url: 'statementChannel/exceptCompany',
        data,
    });
    if (response.data.code) {
        totalExceptCom = response.data.data.objectCount.amount_win;
    }
    return totalExceptCom;

};
export default {
    fetchListStatement,
    fetchCountExceptSenior,
    fetchCountExceptCompany
};